import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Gallery from '../../components/Gallery';

export default function SlantMemorial() {
  const data = useStaticQuery(graphql`
    query SlantMemorialQuery {
      allCloudinaryMedia(
        filter: { tags: { eq: "slant" } }
        sort: { fields: public_id, order: ASC }
      ) {
        nodes {
          id
          secure_url
        }
      }
    }
  `);

  return (
    <Gallery
      galleryImages={data.allCloudinaryMedia.nodes}
      galleryName="Slant Memorials"
      title="Slant Memorials Gallery | Sheboygan Monument &amp; Stone Works"
      description="Slant memorials that we have made for customers."
      urlPath="slant-memorials"
    />
  );
}
